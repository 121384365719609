import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Container } from 'react-bootstrap';

function BasicExample() {
    return (
        <Navbar bg="dark" data-bs-theme="dark">
            <Container>
                <Nav className="me-auto">
                    <Nav.Link href="/">Post</Nav.Link>
                    <Nav.Link href="/guestbook">Guestbook</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}

export default BasicExample;