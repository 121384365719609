import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import "./Guestbook.css"; // Stil für die Sprechblasen

function Guestbook() {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        // Lade alle Nachrichten vom Backend, wenn die Komponente montiert wird
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        try {
            // Sende eine GET-Anfrage an das Backend, um alle gespeicherten Nachrichten abzurufen
            const response = await axios.get('http://localhost:8080/api/messages');
            setMessages(response.data.reverse());
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h1 className="text-center mb-4">Guestbook</h1>
                    <div className="message-container">
                        {messages.map((message, index) => (
                            <div key={index} className={`message-bubble ${index % 2 === 0 ? "other" : "self"}`}>
                                <p><strong>From:</strong> {message.nickname}</p>
                                <p><strong>Message:</strong> {message.message}</p>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Guestbook;
