import './App.css';
import React from 'react'; // Import React
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PostView from './views/PostView.jsx';
import Guestbook from './views/Guestbook.jsx';
import Navbar from './components/navbar.jsx';


function App() {
  return (
    <>
      <Navbar />
      <Router>
        <div>
          <Routes>
            <Route path="/" Component={PostView} />
            <Route path="/guestbook" Component={Guestbook} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
