import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import "./PostView.css"; // Stil für die PostView

function PostView() {
    const [message, setMessage] = useState("");
    const [nickname, setNickname] = useState("");
    const [messagePosted, setMessagePosted] = useState(false);
    const [error, setError] = useState("");

    const handleNicknameChange = (event) => {
        setNickname(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async () => {
        if (!message.trim() || !nickname.trim()) {
            setError("Please enter a message and a nickname.");
            return;
        }

        try {
            // Sende eine POST-Anfrage an das Backend, um die Nachricht zu speichern
            await axios.post('http://localhost:8080/api/message', { message, nickname });
            setMessagePosted(true);
            setMessage("");
            setNickname("");
            setError("");
        } catch (error) {
            console.error('Error:', error);
            setError("Error posting message. Please try again.");
        }
    };


    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <h1 className="text-center mb-4">Post a Message</h1>
                    <Form>
                        <Form.Group controlId="formNickname">
                            <Form.Label>Nickname</Form.Label>
                            <Form.Control type="text" placeholder="Enter your nickname" value={nickname} onChange={handleNicknameChange} />
                        </Form.Group>

                        <Form.Group controlId="formMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Enter your message" value={message} onChange={handleMessageChange} />
                        </Form.Group>

                        <Button variant="primary" onClick={handleSubmit}>Submit</Button>
                    </Form>
                    {messagePosted && (
                        <Alert variant="success" className="mt-3">
                            Message posted successfully!
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" className="mt-3">
                            {error}
                        </Alert>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default PostView;
